import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from "axios"
import { cookies } from "utility/cookies"
import { getOneHourFromNow } from "utility/DateTimeUtils"

export const fetchJWTGoogle = createAsyncThunk("firebase/JWT/Google", async (body: object) => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/google`, body)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchJWTFacebook = createAsyncThunk("firebase/JWT/Facebook", async (body: object) => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/facebook`, body)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

const session = () => {
  const userData = cookies.get("userData")
  if (userData && userData.type === "Google") {
    return userData
  }
  if (userData && userData.type === "Facebook") {
    return userData
  }
  return null
}

export const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    JWT: null || session(),
    JWTError: null,
    JWTLoading: false
  },
  reducers: {
    removeSession: (state, action) => {
      state.JWTLoading = false
      state.JWT = null
      cookies.remove("userData", { path: "/" })
      // cookies.remove("userData", { path: "/", domain: process.env.REACT_APP_COOKIE_DOMAIN })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJWTGoogle.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchJWTGoogle.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        getOneHourFromNow()
        cookies.set(
          "userData",
          { ...payload, expires: new Date(getOneHourFromNow()), type: "Google" },
          { path: "/" }
          // { path: "/", domain: process.env.REACT_APP_COOKIE_DOMAIN }
        )
      })
      .addCase(fetchJWTGoogle.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })

      .addCase(fetchJWTFacebook.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchJWTFacebook.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        getOneHourFromNow()
        cookies.set(
          "userData",
          { ...payload, expires: new Date(getOneHourFromNow()), type: "Facebook" },
          { path: "/" }
          // { path: "/", domain: process.env.REACT_APP_COOKIE_DOMAIN }
        )
      })
      .addCase(fetchJWTFacebook.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })
  }
})

export const { removeSession } = authSlice.actions
export default authSlice.reducer
