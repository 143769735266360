import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import io from "socket.io-client"

import axios from "axios"
import { RootState } from "redux/store"
import QRCode from "../../../assets/images/QRCode.png"

export const fetchCheckout = createAsyncThunk<any, void, { state: RootState; rejectValue: any }>(
  "checkout/fetchCheckout",
  async (_: void, thunkAPI) => {
    try {
      const { bodyData, accessToken } = thunkAPI.getState().checkout.checkoutArguments
      // if (bodyData.paymentMethod === "pix" || bodyData.paymentMethod === "matic") {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/store/checkout`, bodyData, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })

      return data
      // }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response)
    }
  }
)

export const fetchCheckoutTransactionRequestStatus = createAsyncThunk(
  "checkout/transactionRequestStatus",
  async ({ transactionId, accessToken }: any) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/transactions/${transactionId}/transactionRequest/status`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    return data.status
  }
)

export const watchCheckoutTransaction = (transactionId) => {
  const protocol = new URL(process.env.REACT_APP_API_URL).protocol
  const host = new URL(process.env.REACT_APP_API_URL).host
  const hostname = `${protocol}//${host}`
  const socket = io(hostname)

  socket.on("connect", () => {
    console.log("socket connected")
    console.log(`Monitorando Transaction: ${transactionId}`)
    socket.emit("lastStatusChangeFromTransaction", { transactionId })
  })

  socket.on("disconnect", () => {
    socket.removeAllListeners()
    console.log("socket disconnected")
  })

  socket.on("error", (error) => {
    socket.removeAllListeners()
    console.log("socket error", error)
  })
  return socket
}
export const checkoutSlice = createSlice({
  name: "checkoutSlice",
  initialState: {
    modalRescue: false,
    modalSelectPayment: false,
    modalQRCodePayment: false,

    checkoutArguments: {
      bodyData: {
        paymentMethod: "pix",
        paymentProvider: "",
        paymentCurrency: "brl",
        amount: "1",
        offerId: ""
      },
      accessToken: ""
    },

    checkoutQrCode: {
      qrcode: "",
      qrcodeText: "",
      to: "",
      value: "",
      data: "",
      network: "",
      valueInWei: ""
    },
    checkoutQrCodeLoading: true,
    checkoutQrCodeErrorMessage: "",

    checkoutToken: {
      id: "",
      offerId: "",
      sale: {
        price: "",
        currency: "",
        decimals: ""
      },
      seller: {
        id: "",
        name: ""
      },
      offerType: "",
      priceInBrl: "",
      tokenId: "",
      companyId: "",
      communityId: "",
      collectionId: "",
      transactionType: "",
      owners: [],
      inventory: 0,
      amount: 0,
      collection: {},
      voucherCode: "",
      name: ""
    },

    checkoutResponse: {
      paymentObject: {
        qrcode: "",
        qrcodeText: "",
        to: "",
        value: "",
        data: "",
        network: "",
        valueInWei: ""
      },
      transactionId: "",
      status: ""
    },
    checkoutResponseLoading: true,
    checkoutResponseErrorMessage: undefined,

    checkoutTransactionRequestStatus: "",
    checkoutTransactionRequestStatusLoading: false,
    checkoutTransactionRequestStatusErrorMessage: undefined,
    checkoutPaymentStatus: "",

    shouldOpenSelectPaymentModal: false,
    shouldDoPost: false,
    isButtonLoading: false,
    isPolling: false,

    checkoutTimer: 300,
    checkoutPaymentMethod: "pix"
  },
  reducers: {
    setPaymentMethod: (state, { payload }) => {
      state.checkoutPaymentMethod = payload
    },
    setCheckoutTimer: (state, { payload }) => {
      state.checkoutTimer = payload
    },
    setCheckoutResponseErrorMessage(state, { payload }) {
      state.checkoutResponseErrorMessage = payload
    },
    setIsPolling: (state, { payload }) => {
      state.isPolling = payload
    },
    setShouldDoPost: (state, { payload }) => {
      state.shouldDoPost = payload
    },
    setIsButtonLoading: (state, { payload }) => {
      state.isButtonLoading = payload
    },
    setShouldOpenSelectPaymentModal: (state, { payload }) => {
      state.shouldOpenSelectPaymentModal = payload
    },
    setCheckoutModal: (state, { payload }) => {
      state.modalRescue = payload
    },
    setShowModalSelectPayment: (state, { payload }) => {
      state.modalSelectPayment = payload
      state.checkoutQrCodeLoading = true
      state.checkoutQrCode = {
        qrcode: "",
        qrcodeText: "",
        to: "",
        value: "",
        data: "",
        network: "",
        valueInWei: ""
      }
    },
    setShowModalQRCodePayment: (state, { payload }) => {
      state.modalQRCodePayment = payload
      if (payload) {
        state.isButtonLoading = true
      } else {
        state.checkoutQrCodeErrorMessage = null
      }
    },
    clearCheckoutQrCode: (state) => {
      state.checkoutQrCode = {
        qrcode: "",
        qrcodeText: "",
        to: "",
        value: "",
        data: "",
        network: "",
        valueInWei: ""
      }
    },
    clearCheckoutResponse: (state) => {
      state.checkoutResponse = {
        paymentObject: {
          qrcode: "",
          qrcodeText: "",
          to: "",
          value: "",
          data: "",
          network: "",
          valueInWei: ""
        },
        transactionId: "",
        status: ""
      }
    },
    clearBodyData: (state) => {
      state.checkoutArguments.bodyData = {
        paymentMethod: "pix",
        paymentProvider: "",
        paymentCurrency: "brl",
        amount: "1",
        offerId: ""
      }
    },
    setCheckoutQrCode: (state, { payload }) => {
      state.checkoutQrCode = payload
    },
    setCheckoutToken: (state, { payload }) => {
      state.checkoutToken = payload
    },
    setCheckoutQrCodeErrorMessage: (state, { payload }) => {
      state.checkoutQrCodeErrorMessage = payload
    },
    setCheckoutQrCodeLoading: (state, { payload }) => {
      state.checkoutQrCodeLoading = payload
    },
    setCheckoutArguments: (state, { payload }) => {
      state.checkoutArguments = payload
    },
    setCheckoutTransactionRequestStatus: (state, { payload }) => {
      state.checkoutTransactionRequestStatus = payload
    },
    setCheckoutPaymentStatus: (state, { payload }) => {
      state.checkoutPaymentStatus = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCheckout.pending, (state) => {
        state.checkoutResponseLoading = true
        state.shouldDoPost = false
      })
      .addCase(fetchCheckout.fulfilled, (state, { payload }) => {
        state.checkoutResponseLoading = false
        state.shouldDoPost = false
        state.checkoutResponse = payload
        if (payload?.paymentObject) {
          state.isPolling = true
          state.checkoutQrCode = payload?.paymentObject
          state.checkoutQrCodeLoading = false
        }
      })
      .addCase(fetchCheckout.rejected, (state, { payload }) => {
        state.checkoutResponseLoading = false
        state.checkoutResponseErrorMessage = payload.data.message
        state.isButtonLoading = false
        state.isPolling = false
        state.shouldDoPost = false
      })

      .addCase(fetchCheckoutTransactionRequestStatus.pending, (state) => {
        state.checkoutTransactionRequestStatusLoading = true
      })
      .addCase(fetchCheckoutTransactionRequestStatus.fulfilled, (state, { payload }: any) => {
        state.checkoutTransactionRequestStatusLoading = false
        state.checkoutTransactionRequestStatus = payload
      })
      .addCase(fetchCheckoutTransactionRequestStatus.rejected, (state, { payload }) => {
        state.checkoutTransactionRequestStatusLoading = false
        state.checkoutTransactionRequestStatusErrorMessage = payload
      })
  }
})

export const {
  setCheckoutTimer,
  setPaymentMethod,
  clearBodyData,
  setShowModalSelectPayment,
  setShouldDoPost,
  setIsButtonLoading,
  setIsPolling,
  setCheckoutResponseErrorMessage,
  setShowModalQRCodePayment,
  setCheckoutModal,
  clearCheckoutQrCode,
  setCheckoutToken,
  setCheckoutQrCodeErrorMessage,
  setCheckoutQrCode,
  setCheckoutArguments,
  setCheckoutQrCodeLoading,
  setCheckoutTransactionRequestStatus,
  setCheckoutPaymentStatus,
  clearCheckoutResponse,
  setShouldOpenSelectPaymentModal
} = checkoutSlice.actions

export default checkoutSlice.reducer
