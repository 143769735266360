import { CreatedToken } from "./../../../utility/request-interfaces/collection-types.d"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from "axios"
import { CreatedCollection } from "interfaces/collection"

export const fetchToken = createAsyncThunk("token/fetchToken", async (id: string) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/tokens/${id}`)
  return data
})

export const fetchBenefits = createAsyncThunk("token/fetchBenefits", async (id: string) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/benefits/token/${id}`)
  return data
})

export const fetchCollection = createAsyncThunk("token/fetchCollection", async (id: string) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/collections/${id}`)
  return data
})

export const fetchSpecificTokenFromUserId = createAsyncThunk(
  "token/fetchSpecificTokenFromUserId",
  async (payload: any) => {
    const { tokenUuid, jwtAccessToken } = payload
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/tokens/owned/${tokenUuid}`, {
      headers: {
        Authorization: `Bearer ${jwtAccessToken}`
      }
    })
    if (Object.keys(data).length === 0) {
      return { owned: false, quantity: 0 }
    }
    // setTokenOwned(true)
    // setTokenOwnedQuantity(data?.owners[0]?.quantity)
    return { owned: true, quantity: data?.owners[0]?.quantity }
  }
)

export const fetchMainTokenOfferByTokenId = createAsyncThunk(
  "token/fetchMainTokenOfferByTokenId",
  async (tokenId: string) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/store/token/${tokenId}`)
    return data
  }
)

export const fetchMarketPlaceTokenOffersByTokenId = createAsyncThunk(
  "token/fetchMarketPlaceTokenOffersByTokenId",
  async (tokenId: string) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/marketplace/token/${tokenId}`)
    return data
  }
)

function compareTokenOffersTokenIds(a: any, b: any) {
  if (a?.tokenData?.tokenId < b?.tokenData?.tokenId) {
    return -1
  }
  if (a?.tokenData?.tokenId > b?.tokenData?.tokenId) {
    return 1
  }
  return 0
}

export const fetchCollectionRelatedMainTokenOffers = createAsyncThunk(
  "token/fetchCollectionRelatedMainTokenOffers",
  async (collectionId: string) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/store/collection/${collectionId}`)
    const tokenOffers = data.tokenOffers
    if (tokenOffers?.length > 0) {
      tokenOffers.sort(compareTokenOffersTokenIds)
    }
    return tokenOffers
  }
)

export const fetchCommunityRelatedMainTokenOffers = createAsyncThunk(
  "token/fetchCommunityRelatedMainTokenOffers",
  async (communityId: string) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/store/community/${communityId}`)
    // const tokenOffers = data.tokenOffers
    // if (tokenOffers?.length > 0) {
    //   tokenOffers.sort(compareTokenOffersTokenIds)
    // }
    return data
  }
)

const initialTokenState = {} as CreatedToken

const initialCollectionState = {} as CreatedCollection

export const tokenSlice = createSlice({
  name: "tokenSlice",
  initialState: {
    activeTokenMenu: "information",

    token: initialTokenState,
    tokenLoading: true,
    tokenErrorMessage: undefined,
    owned: false,
    ownedQuantity: 0,

    collectionRelatedTokenOffers: [],
    collectionRelatedTokenOffersLoading: true,
    collectionRelatedTokenOffersErrorMessage: undefined,

    communityRelatedTokenOffers: undefined,
    communityRelatedTokenOffersLoading: true,
    communityRelatedTokenOffersErrorMessage: undefined,

    collection: initialCollectionState,
    collectionLoading: false,
    collectionErrorMessage: undefined,

    benefits: [],
    benefitsLoading: false,
    benefitsErrorMessage: undefined,

    tokenOffersLoading: true,
    tokenOffersErrorMessage: undefined,

    mainTokenOffer: undefined,
    mainTokenOfferLoading: true,
    mainTokenOfferErrorMessage: undefined,

    marketPlaceTokenOffers: [],
    marketPlaceTokenOffersLoading: true,
    marketPlaceTokenOffersErrorMessage: undefined,

    modalTokenOffers: false
  },
  reducers: {
    setActiveTokenMenu: (state, { payload }) => {
      state.activeTokenMenu = payload
      window.sessionStorage.setItem("activeTokenMenu", payload)
    },
    setTokenInventory: (state, { payload }) => {
      state.token.inventory = payload
    },
    setTokenOwned: (state, { payload }) => {
      state.owned = payload
    },
    setTokenPriceInBrl: (state, { payload }) => {
      state.token.priceInBrl = payload
    },
    setModalTokenOffers: (state, { payload }) => {
      state.modalTokenOffers = payload
    },
    setTokenOwnedQuantity: (state, { payload }) => {
      state.ownedQuantity = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchToken.pending, (state) => {
        state.tokenLoading = true
      })
      .addCase(fetchToken.fulfilled, (state, { payload }) => {
        state.tokenLoading = false
        state.token = payload
      })
      .addCase(fetchToken.rejected, (state, { payload }) => {
        state.tokenLoading = false
        state.tokenErrorMessage = payload
      })

      .addCase(fetchSpecificTokenFromUserId.pending, (state) => {
        state.tokenLoading = true
      })
      .addCase(fetchSpecificTokenFromUserId.fulfilled, (state, { payload }: any) => {
        state.tokenLoading = false
        state.owned = payload.owned
        state.ownedQuantity = payload.quantity
      })
      .addCase(fetchSpecificTokenFromUserId.rejected, (state, { payload }) => {
        state.tokenLoading = false
      })

      .addCase(fetchCollection.pending, (state) => {
        state.collectionLoading = true
      })
      .addCase(fetchCollection.fulfilled, (state, { payload }) => {
        state.collectionLoading = false
        state.collection = payload
      })
      .addCase(fetchCollection.rejected, (state, { payload }) => {
        state.collectionLoading = false
        state.collectionErrorMessage = payload
      })

      .addCase(fetchBenefits.pending, (state) => {
        state.benefitsLoading = true
      })
      .addCase(fetchBenefits.fulfilled, (state, { payload }) => {
        state.benefitsLoading = false
        state.benefits = payload
      })
      .addCase(fetchBenefits.rejected, (state, { payload }) => {
        state.benefitsLoading = false
        state.benefitsErrorMessage = payload
      })

      .addCase(fetchMainTokenOfferByTokenId.pending, (state) => {
        state.mainTokenOfferLoading = true
      })
      .addCase(fetchMainTokenOfferByTokenId.fulfilled, (state, { payload }) => {
        state.mainTokenOfferLoading = false
        state.mainTokenOffer = payload
      })
      .addCase(fetchMainTokenOfferByTokenId.rejected, (state, { payload }) => {
        state.mainTokenOfferLoading = false
        state.mainTokenOfferErrorMessage = payload
      })

      .addCase(fetchMarketPlaceTokenOffersByTokenId.pending, (state) => {
        state.marketPlaceTokenOffersLoading = true
      })
      .addCase(fetchMarketPlaceTokenOffersByTokenId.fulfilled, (state, { payload }) => {
        state.marketPlaceTokenOffersLoading = false
        state.marketPlaceTokenOffers = payload
      })
      .addCase(fetchMarketPlaceTokenOffersByTokenId.rejected, (state, { payload }) => {
        state.marketPlaceTokenOffersLoading = false
        state.marketPlaceTokenOffersErrorMessage = payload
      })

      .addCase(fetchCollectionRelatedMainTokenOffers.pending, (state) => {
        state.collectionRelatedTokenOffersLoading = true
      })
      .addCase(fetchCollectionRelatedMainTokenOffers.fulfilled, (state, { payload }) => {
        state.collectionRelatedTokenOffersLoading = false
        state.collectionRelatedTokenOffers = payload
      })
      .addCase(fetchCollectionRelatedMainTokenOffers.rejected, (state, { payload }) => {
        state.collectionRelatedTokenOffersLoading = false
        state.collectionRelatedTokenOffersErrorMessage = payload
      })

      .addCase(fetchCommunityRelatedMainTokenOffers.pending, (state) => {
        state.communityRelatedTokenOffersLoading = true
      })
      .addCase(fetchCommunityRelatedMainTokenOffers.fulfilled, (state, { payload }) => {
        state.communityRelatedTokenOffersLoading = false
        state.communityRelatedTokenOffers = payload
      })
      .addCase(fetchCommunityRelatedMainTokenOffers.rejected, (state, { payload }) => {
        state.communityRelatedTokenOffersLoading = false
        state.communityRelatedTokenOffersErrorMessage = payload
      })
  }
})

export const {
  setActiveTokenMenu,
  setTokenInventory,
  setTokenOwned,
  setTokenPriceInBrl,
  setModalTokenOffers,
  setTokenOwnedQuantity
} = tokenSlice.actions

export default tokenSlice.reducer
