import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const defaultWhiteLabel = {
  id: "",
  companyId: "",
  communityId: "",
  faq: {
    externalLink: "",
    questions: [
      {
        question: "",
        answer: ""
      },
      {
        question: "",
        answer: ""
      }
    ]
  },
  support: {
    termsAndConditions: "https://docs-gotokens.goblockchain.io/legal/termos-e-condicoes",
    privacyPolicy: "https://docs-gotokens.goblockchain.io/legal/termos-e-condicoes",
    externalLink: "",
    socialSupport: {
      facebook: "",
      instagram: "",
      twitter: "",
      linkedin: "",
      youtube: "",
      tiktok: "",
      whatsapp: "",
      telegram: "",
      discord: "",
      email: "",
      phone: ""
    }
  },
  socials: {
    facebook: "",
    instagram: "",
    twitter: "",
    linkedin: "",
    youtube: "",
    tiktok: "",
    whatsapp: "",
    telegram: "",
    discord: "",
    email: "",
    phone: ""
  },
  tracking: {
    googleAnalytics: "GA-DEFAULT",
    googleTagManager: "GTM-DEFAULT",
    facebookPixel: "FBP-DEFAULT"
  },
  layout: {
    webStore: {
      link: "https://hml-store.goblockchain.io/",
      title: "Token - goBlockchain",
      favicon: "https://goblockchain.io/wp-content/uploads/2021/09/cropped-jkjhkjhkjkhjkhjkhjkhj-32x32.png",
      metaDescription: "",
      metaFeatureImage: "",
      header: {
        logo: "https://goblockchain.io/wp-content/uploads/2021/09/Componente-8-–-1.png",
        backgroundColor: "#FFF",
        textColor: "#00000F0",
        hoverTextColor: "#00aa55",
        buttonColor: "#1573bb"
      },
      body: {
        backgroundColor: "#f8f8f8",
        backgroundImage: "",
        textColor: "#00eeee",
        hoverTextColor: "",
        highlightColor: "#009FE3",
        externalLinkColor: "#4E9FD8",
        buttonColor: "#009FE3",
        buttonTextColor: "#FFF",
        cardBackgroundColor: "#FFFFFF",
        cardBackgroundFooterColor : "#F8F8F8",
        cardTextColor : "#454545",
        cardBadgeColor : "#AFAFAF",
        cardTextBadgeColor : "#FFFFFF"
      },
      footer: {
        logo: "https://goblockchain.io/wp-content/uploads/2022/12/LOGO-GOBLOCK.png.webp",
        backgroundColor: "#000",
        textColor: "#FFFFFFF0",
        hoverTextColor: "#FFFFFF"
      },
      loading: {
        color: "#009FE3",
        backgroundColor: ""
      }
    },
    userSpace: {
      link: "https://hml-weispace.goblockchain.io/",
      title: "",
      favicon: "",
      menu: {
        logo: ""
      },
      login: {
        logo: ""
      }
    }
  },
  auth: {
    cookieDomain: "",
    google: {
      enabled: true
    },
    facebook: {
      enabled: true
    },
    apple: {
      enabled: true
    },
    SMS: {
      enabled: true
    },
    metamask: {
      enabled: true,
      deeplink: ""
    }
  }
}

export const fetchWhiteLabel = createAsyncThunk("whitelabel", async ({ communityId }: any) => {
  try {
    if (!communityId) {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/white-label/templates/default`)
      if (data) return data
      return defaultWhiteLabel
    }
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/white-label/community/${communityId}`)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const whitelabelConfigSlice = createSlice({
  name: "whitelabelConfig",
  initialState: {
    whitelabel: defaultWhiteLabel,
    loading: true,
    errorMessage: undefined
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWhiteLabel.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchWhiteLabel.fulfilled, (state, { payload }) => {
        state.loading = false
        state.whitelabel = payload
      })
      .addCase(fetchWhiteLabel.rejected, (state, { payload }) => {
        state.loading = false
        state.errorMessage = payload
      })
  }
})

export type WhiteLabelConfigState = ReturnType<typeof whitelabelConfigSlice.reducer>
export default whitelabelConfigSlice.reducer
