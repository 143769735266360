import React, { createContext, useContext, useState } from "react"

export interface IContext {
  shouldRescue: boolean
  setShouldRescue: (shouldRescue: boolean) => void
}
const Context = createContext<IContext>(null)

export const ContextProvider = ({ children }) => {
  const [shouldRescue, setShouldRescue] = useState(false)

  return (
    <Context.Provider
      value={{
        shouldRescue,
        setShouldRescue
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useGlobalContext = () => {
  const context = useContext(Context)
  if (!context) {
    throw new Error("useGlobalContext must be used within a StoreProvider")
  }
  return context
}
